import React from 'react';
import { graphql } from 'gatsby';
import { Row, Col } from 'react-bootstrap';
import CoverImageTitle from '../components/coverImageTitle';
import ContactForm from '../components/ContactForm';

const KontaktTemplate = (props) => {
  const {
    data: {
      wordpressPage: {
        acf: {
          title,
          color_template,
          contact_data_title,
          contact_detail,
          facebook_link,
          background_image: {
            localFile: {
              childImageSharp: {
                fluid,
              },
            },
          },
        },
      },
      contactForm,
    },
    pathContext,
  } = props;

  return (
    <>
      <CoverImageTitle title={title} fluid={fluid} />
      <ContactForm
        fluid={fluid}
        contactForm={contactForm}
        lang={pathContext.lang}
        color_template={color_template}
      />
      <div className="kontakt-container">

        <div className="footer-contact">
          <h2 className="footer-contact-title">{contact_data_title}</h2>
          <div className="container">
            <Row>

              {contact_detail
                ? (
                  <>
                    {contact_detail.map((detail) => (
                      <Col md={6} key={detail.label}>
                        <h3 className="title-contact">
                          {detail.label}
                          {' '}
                        </h3>

                        {detail.link && detail.open_new_tab === true
                          ? <a className="contact-data" target="_blank" rel="noreferrer" href={detail.link}>{detail.value}</a>
                          : <p className="contact-data">{detail.value}</p>}

                      </Col>
                    ))}

                  </>
                )
                : ''}

              <Col md={6}>
                <p className="facebook-icon">
                  {facebook_link
                   && <a target="_blank" rel="noreferrer" href={facebook_link}>f</a>}

                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
   query($lang: String) 
    {
      wordpressPage(
      slug: { eq: "kontakt" }
      wpml_current_locale: { eq: $lang }
    ) {
      title
      path
      yoast_head
    }
    wordpressAcfOptions {
      options {
        kontakt {
        de_label
        en_label
      }
      produkte {
        de_label
        en_label
      }
      referenzen {
        de_label
        en_label
      }
      sprache {
        de_label
        en_label
      }
        consent_checkbox_label
        consent_hint
        dropdown_label
        dropdown_placeholder
        # title
        time_window_dropdown_options {
          title
        }
        contact_icons_footer {
          email
          phone
        }
        bottom_footer_left {
          name
          english_name
          url
        }
        bottom_footer_right {
          name
          english_name
          url
        }
        copyright_text
        send_success_message
        dropdown_validation_error_message
      }
    }
    wordpressPage(slug: { eq: "kontakt" }
      wpml_current_locale: { eq: $lang }
    ) {
      title
      path
      acf {
        title
        color_template
        contact_data_title
        contact_detail {
          label
          link
          value
          open_new_tab
        }
        facebook_link
        background_image {
          localFile {
            childImageSharp {

                fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid,
                }
              
            }
          }
        }
      }
    }

    contactForm: wordpressPage(slug: {eq: "contact-form-options"}, wpml_current_locale: {eq:  $lang }) {
        acf {
          color_template
          contact_form_title
          description
          dropdown_label
          dropdown_placeholder
          consent_hint
          consent_checkbox_label
          send_success_message
          dropdown_validation_error_message
          time_window_dropdown_options {
            title
          }
          message_placeholder
          send_button
        }
      }
  }
   
`;
export default KontaktTemplate;
